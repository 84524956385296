import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from 'vue-router';
export default defineComponent({
  setup: function setup() {
    var router = useRouter();
    var index = ref(2);

    var handleGuige = function handleGuige() {
      index.value = 1;
      router.push('/introduction');
    };

    var handleRuanjian = function handleRuanjian() {
      index.value = 2;
      router.push('/software');
    };

    var handleBuy = function handleBuy() {
      index.value = 3;
      router.push('/buy');
    };

    onMounted(function () {
      handlebackTop();
    });

    var handlebackTop = function handlebackTop() {
      content.scrollIntoView();
      console.log("000");
    };

    return {
      index: index,
      handleGuige: handleGuige,
      handleRuanjian: handleRuanjian,
      handleBuy: handleBuy,
      handlebackTop: handlebackTop
    };
  }
});